import React, { Component } from 'react'
import Link from 'gatsby-link'

import '../styles/header.scss';
import '../styles/hamburger.scss';

import mobileNavIcon from '../assets/images/menu.svg';

export default class Header extends Component {

  state = {
    scrolled: false,
    mobileOpen: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 80) {
      this.setState({ scrolled: true }); 
    } else {
      this.setState({ scrolled: false, mobileOpen: false }); 
    }
  }

  getHeaderClass = () => {
    return `header${this.state.scrolled ? ' header--scrolled' : ''}${this.props.isFrontpage ? ' header--transparent' : ''}`;
  }

  getMobileHeaderClass = () => {

    if (this.state.mobileOpen) {
      return 'header__content-mobile header__content-mobile--active';
    }

    return 'header__content-mobile';
  }

  onMobileClicked = () => {
    const { mobileOpen } = this.state;

    this.setState({ mobileOpen: !mobileOpen });
  }

  getHamburgerMenuClass = () => {
    return `hamburger-menu${this.state.mobileOpen ? ' active' : ''}${this.props.isFrontpage ? ' transparent' : ''}`;
  }

  closeMobileMenu = () => {
    this.setState({ mobileOpen: false });
  }

  render() {
    return (
      <div className={this.getHeaderClass()}>
        <div className="header__content">
          <Link onClick={this.closeMobileMenu} to={'/'}>home</Link>
          <Link onClick={this.closeMobileMenu} to={'/about'}>about</Link>
          <Link onClick={this.closeMobileMenu} to={'/videos'}>videos</Link>
          <Link onClick={this.closeMobileMenu} to={'/blog'}>blog</Link>
          <Link onClick={this.closeMobileMenu}to={'/contact'}>contact</Link>
        </div>
        <div onClick={this.onMobileClicked} className="MobileNavIcon">
        <div className="menu-wrapper">
          <div className={this.getHamburgerMenuClass()}></div>	  
        </div>
        </div>
        <div className={this.getMobileHeaderClass()}>
          <Link onClick={this.closeMobileMenu} to={'/'}>home</Link>
          <Link onClick={this.closeMobileMenu} to={'/about'}>about</Link>
          <Link onClick={this.closeMobileMenu} to={'/videos'}>videos</Link>
          <Link onClick={this.closeMobileMenu} to={'/blog'}>blog</Link>
          <Link onClick={this.closeMobileMenu} to={'/contact'}>contact</Link>
        </div>
      </div>
    )
  }
}
