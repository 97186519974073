import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { StaticQuery, graphql } from "gatsby"
import hljs from 'highlight.js';
import favicon from '../../assets/images/favicon.png';

import Header from '../header';
import './layout.css';
import '../../styles/normalize.scss';
import '../../styles/fonts.scss';
import '../../styles/syntaxHighlighting.scss';

export default ({ children, location }) => {
  return <div>
    <Helmet
      title={"Daniel Grek Sanchez Castellanos"}
      meta={[
        {
          name: 'description',
          content:
            'My name is Daniel Grek Sanchez Castellanos. I am developer and entrepreneur. In this website I share my thoughts and experience. Enjoy!',
        },
        {
          name: 'keywords',
          content:
            'daniel grek sanchez castellanos, daniel, grek, sanchez, castellanos, developer, web developer, javascript, c#, php, css, html, programmer, programming, react, reactjs, gatsbyjs, gastby, lithuania, vilnius, siteocean, patton and schachte',
        },
      ]}
    >
      <link rel="shortcut icon" type="image/png" href={favicon} />
    </Helmet>
    <Header
      isFrontpage={location.pathname === '/'}
      siteTitle={"Daniel Grek Sanchez Castellanos"}
    />
    <div className="Page">
      <TransitionHandler>{children}</TransitionHandler>
    </div>
  </div>
};

class TransitionHandler extends React.Component {
  componentDidMount() {
    hljs.initHighlightingOnLoad();
  }

  componentDidUpdate() {
    const blocks = document.querySelectorAll('pre code');
    blocks.forEach(item => {
      hljs.highlightBlock(item);
    });
  }

  render() {
    return this.props.children;
  }
}
